<template>
    <LiefengContent>
        <template #title>脱敏记录</template>
        <template #toolsbarRight>
        </template>
        <template #contentArea>
            <LiefengTable
                :talbeColumns="talbeColumns"
                :tableData="tableData"
                :loading="loading"
                :fixTable="true"
                :curPage="page"
                :total="total"
                :pagesizeOpts="[20, 30, 50, 100]"
                :page-size="pageSize"
                @hadlePageSize="hadlePageSize"
            ></LiefengTable>
        </template>
    </LiefengContent>
</template>

<script>
import LiefengContent from "@/components/LiefengContent3"
import LiefengTable from "@/components/LiefengTable"
export default {
    components: {
        LiefengContent,
        LiefengTable,
    },
    data() {
        return {
            search:{
                userName:'',
                mobile:'',
                status:'全部'
            },

            tableData: [],
            talbeColumns: [
                {
                    title: "员工名称",
                    key: "staffName",
                    minWidth: 200,
                    align: "center",
                },
                {
                    title: "机构名称",
                    key: "orgName",
                    minWidth: 200,
                    align: "center",
                },
                {
                    title: "操作内容",
                    key: "accessName",
                    minWidth: 300,
                    align: "center",
                },

                {
                    title: "操作时间",
                    minWidth: 200,
                    align: "center",
                    render: (h, params) => {
                        return h("div", {}, params.row.gmtCreate ? this.$core.formatDate(new Date(params.row.gmtCreate), "yyyy-MM-dd hh:mm:ss") : "")
                    },
                },
            ],
            loading: false,
            pageSize: 20,
            total: 0,
            page: 1,
        }
    },

    methods: {
        //分页
        hadlePageSize(val){
            this.page = val.page
            this.pageSize = val.pageSize
            this.getList()
        },
        getList(){
            this.loading = true
            this.$get('https://liefeng-gateway.test.gongxiangplat.com/api/auth/pc/sensitive/querySensitiveLog',{
                orgCode:parent.vue.loginInfo.userinfo.orgCode,
                page:this.page,
                pageSize:this.pageSize
            }).then( res => {
                this.loading = false
                if(res.code == 200 && res.dataList){
                    this.tableData = res.dataList
                    this.total = res.maxCount
                    this.pageSize = res.currentPage
                }else{
                    this.$MEssage.error({
                        content:'获取数据失败',
                        background:true
                    })
                }
            })
        },
    },
    created() {
        this.getList()
    },
    components: {
        LiefengContent,
        LiefengTable,
    },
}
</script>
    
<style scoped lang='less'>
</style>